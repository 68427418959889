import React from 'react';
import { AppBar, Toolbar, Typography, Button, Card, CardContent, Grid, Container } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const App = () => {
  const carouselItems = [
    { url: '/api/placeholder/1200/400', alt: 'Banner 1' },
    { url: '/api/placeholder/1200/400', alt: 'Banner 2' },
    { url: '/api/placeholder/1200/400', alt: 'Banner 3' },
  ];

  return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Your App Name
            </Typography>
            <Button color="inherit">Service</Button>
            <Button color="inherit">Pricing</Button>
            <Button color="inherit">About Us</Button>
          </Toolbar>
        </AppBar>

        <Carousel>
          {carouselItems.map((item, index) => (
              <img key={index} src={item.url} alt={item.alt} style={{ width: '100%', height: '400px', objectFit: 'cover' }} />
          ))}
        </Carousel>

        <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
          <Typography variant="h4" gutterBottom>
            Welcome to Our Secure Push Notification Service
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Service Description
                  </Typography>
                  <Typography variant="body1">
                    Our app allows users to send and receive push notifications securely using QR codes. No sign-up required, ensuring user privacy and ease of use.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Pricing
                  </Typography>
                  <Typography variant="body1">
                    We offer flexible pricing plans to suit your needs. Contact us for more information on our competitive rates.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    About Us
                  </Typography>
                  <Typography variant="body1">
                    We are a dedicated team committed to providing secure and efficient communication solutions for businesses and individuals alike.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Typography variant="h4" style={{ marginTop: '2rem' }} gutterBottom>
            Key Features
          </Typography>
          <Grid container spacing={2}>
            {['QR Code Based', 'No Sign-up Required', 'Secure Communications', 'User Verification', 'Controlled Deletion'].map((feature, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{feature}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
            ))}
          </Grid>
        </Container>
      </div>
  );
};

export default App;